import React from "react";
import { Helmet } from 'react-helmet';

import "./tasbeeh.css";

import { Breadcrumb } from "../../components/breadcrumb/Breadcrumb";

export const Tasbeeh = () => {
    return (
        <>
            <Helmet>
                <title>Tasbeeh | Beloved Muhammad</title>
                <meta name="description" content="Hello" />
                <meta name="keywords" content="Hello" />
            </Helmet>
            <Breadcrumb name="Tasbeeh" />
            <section>
                <div className="bgimg">
                    <div className="middle">
                        <h1>COMING SOON</h1>
                        <hr />
                    </div>
                </div>
            </section>
        </>

    );
};
