import React from "react";
import { Helmet } from 'react-helmet';
import "./home.css";

import { Slider } from "./slider/Slider";
import { Welcome } from "./Welcome";
import { Salawat } from "./Salawat";

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home | Beloved Muhammad</title>
        <meta name="description" content="Hello" />
        <meta name="keywords" content="Hello" />
      </Helmet>
      <Slider />
      <Welcome />
      <Salawat />
    </>
  );
};
