import React, { useState } from 'react';
import ReactCountryFlag from "react-country-flag"
import "./customDropdown.css";

export const CustomDropdown = ({ options, selectedValue, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (value) => {
        onChange(value);
        setIsOpen(false);
    };
    return (
        <div className="custom-dropdown thm-play-btn">

            <div
                className="dropdown-selected"
                onClick={() => setIsOpen(!isOpen)}
            >
                {selectedValue && (
                    <div className="dropdown-selected-content">
                        <ReactCountryFlag
                            countryCode={options.find(opt => opt.id === selectedValue).locale}
                            svg
                            style={{
                                width: '4em',
                                height: '3em',
                            }}
                            title={options.find(opt => opt.id === selectedValue).locale}
                        />

                        {options.find(opt => opt.id === selectedValue).label}
                    </div>
                )}
            </div>
            {isOpen && (
                <div className="dropdown-options">
                    {options.map(option => (
                        <div className="dropdown-option" onClick={() => handleOptionClick(option.id)} >
                            <ReactCountryFlag countryCode={option.locale} svg title={option.locale}
                                style={{
                                    width: '4em', height: '3em',
                                }}
                            />
                            <span>{option.label}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

