import React from 'react';
import "./salawat.css";

import { CurrentSalawat } from './currentSalawat/CurrentSalawat';
import SalawatsList from './salawatslist/SalawatsList';

export const Salawat = () => {
    return (
        <>
            <CurrentSalawat />
            <SalawatsList />
        </>
    );
};
