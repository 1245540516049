import React from 'react';
import { Link } from "react-router-dom";

export const Pagination = ({ currentPage, totalPages, onPageChange }) => {

    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
    }

    return (
        <>
            <div className="pagination-wrap text-center">
                <ul className="pagination">
                    {pages.map((page) => (
                        <li className="active">
                            <Link key={page}
                                onClick={() => onPageChange(page)}
                                disabled={currentPage === page}>
                                {page}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>

        </>
    );
};

