import React from "react";
import { Link } from "react-router-dom";

export const Logo = () => {
    return (
        <>
            <div className="logo" style={{ height: '100px' }}>
                <Link to={'/'}>
                    <img className="green-logo"
                        src="/assets/images/logo/logo.png"
                        alt="logo.png"
                        style={{ height: '100px' }}
                    />
                    <img className="white-logo"
                        src="/assets/images/logo/logo-footer.png"
                        alt="logo.png"
                        style={{ height: '100px' }}
                    />
                </Link>
            </div>
        </>
    );
};
