import React from "react";
import { Helmet } from 'react-helmet';
import "./style.css";
import { Breadcrumb } from "../../components/breadcrumb/Breadcrumb";

export const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Beloved Muhammad</title>
        <meta name="description" content="Hello" />
        <meta name="keywords" content="Hello" />
      </Helmet>
      <Breadcrumb name="About Us" />
      <section>
        <div className="gap gray-bg2">
          <div className="container">
            <div className="msn-wrap">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-lg-6">
                  <div className="msn-thmb-wrap">
                    <a
                      href="/assets/images/resources/hstry-img.png"
                      data-fancybox="gallery"
                      title=""
                    >
                      <img
                        src="/assets/images/resources/hstry-img.png"
                        alt="msn-img1.jpg"
                        style={{ width: 500 }}
                      />
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-lg-6">
                  <div className="msn-desc">
                    <h3>Mission Statement</h3>
                    <p>
                      We unite Muslims of all backgrounds and welcome those interested in Islam by curating a comprehensive library of Darood Sharif (salutations upon Prophet <b>Muhammad</b> (ﷺ)) from around the world. We offer free, beautiful recitations with translations and audio playback, making it easier for everyone to learn, listen, and share these sacred verses.
                    </p>
                  </div>
                  <div className="msn-desc" style={{ marginTop: "25px" }}>
                    <h3>Vision Statement</h3>
                    <p>
                      To be the premier online library for Darood Sharif, offering a global collection accessible to all. We aspire to enrich the lives of Muslims by strengthening their faith through Darood recitations and sparkle curiosity among those interested in Islam, ultimately fostering unity, respect, and a deeper understanding of Prophet <b>Muhammad</b> (ﷺ).
                    </p>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>

  );
};
