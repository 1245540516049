import React, { useState } from 'react';
import { FiCopy, FiCheck } from 'react-icons/fi';  // Import icons
import { SlActionRedo } from "react-icons/sl";
import { RiInstagramLine } from 'react-icons/ri';  // Import Instagram icon

import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    EmailShareButton,
    PinterestShareButton,
    TelegramShareButton,
} from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    PinterestIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
export const SalawatShare = ({ post }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [isPopupOpen, setPopupOpen] = useState(false);

    const postUrl = window.location.href; // Or use a specific URL for the post
    const copyUrlToClipboard = () => {
        navigator.clipboard.writeText(postUrl).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 9000); // Reset to "Copy URL" after 2 seconds
        });
    };

    const handleShareClick = () => {
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
    };

    const shareOnInstagram = () => {
        alert('Instagram does not support direct URL sharing. You can share this content manually.');
        // You can customize this alert with additional instructions or features as needed.
    };


    return (
        <>
            {isPopupOpen && (
                <div style={popupStyle}>
                    <div style={popupInnerStyle}>
                        {/* Close Button with Cross Icon */}
                        <div style={closeIconContainerStyle}>
                            <span style={closeIconStyle} onClick={handleClosePopup}>&times;</span>
                        </div>
                        {/* Heading */}
                        <div className="msn-desc">
                            <h3 style={{ float: "left" }}>{post.title}</h3>
                        </div>
                        <img src={post.image_url} alt="Post" style={imageStyle} />
                        <h3>Share this link</h3>
                        <div style={buttonContainerStyle}>
                            <EmailShareButton url={postUrl} quote={post.name}>
                                <EmailIcon size={32} />
                            </EmailShareButton>
                            <PinterestShareButton>
                                <PinterestIcon size={32} />
                            </PinterestShareButton>
                            <FacebookShareButton url={post.image_url} quote={post.name} imageURL={post.image_url}>
                                <FacebookIcon size={32} />
                            </FacebookShareButton>
                            <TwitterShareButton url={postUrl} title={post.name}>
                                <TwitterIcon size={32} />
                            </TwitterShareButton>
                            <LinkedinShareButton url={postUrl} title={post.name} summary={post.description}>
                                <LinkedinIcon size={32} />
                            </LinkedinShareButton>
                            <WhatsappShareButton url={postUrl} title={post.name}>
                                <WhatsappIcon size={32} />
                            </WhatsappShareButton>
                            <TelegramShareButton url={postUrl} title={post.title}>
                                <TelegramIcon size={32} />
                            </TelegramShareButton>
                            {/* Instagram Share (Manual) */}
                            <button onClick={shareOnInstagram} style={{ padding: '0px 0px !important' }}>
                                <RiInstagramLine size={32} />
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <button onClick={handleShareClick} className='thm-play-btn' style={{ marginLeft: '0px', fontSize: '20px' }}>
                <SlActionRedo /> Share
            </button>
            <button onClick={copyUrlToClipboard} className='thm-play-btn' style={{ marginLeft: '0px', fontSize: '20px' }}>
                {isCopied ? <FiCheck /> : <FiCopy />} Copy
            </button>
        </>
    );

}


// Popup styling
const closeIconContainerStyle = {
    cursor: 'pointer',
    float: 'right',
    color: 'black'
};

const closeIconStyle = {
    fontSize: '24px',
    fontWeight: 'bold'
};

const popupStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
};

const popupInnerStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    width: '600px',
};

const imageStyle = {
    width: '100%',
    height: '200px',
    borderRadius: '8px',
    marginBottom: '15px',
    objectFit: 'cover'
};

const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '15px',
};
