import React from "react";
import { Helmet } from 'react-helmet';
import "./scholars.css";
import { Breadcrumb } from "../../components/breadcrumb/Breadcrumb";

export const Scholars = () => {
  return (
    <>
      <Helmet>
        <title>Scholars | Beloved Muhammad</title>
        <meta name="description" content="Hello" />
        <meta name="keywords" content="Hello" />
      </Helmet>
      <Breadcrumb name="Scholars" />
      <section>
        <div className="bgimg">
          <div className="middle">
            <h1>COMING SOON</h1>
            <hr />
          </div>
        </div>
      </section>
    </>

  );
};
