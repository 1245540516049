export const nav = [
  {
    id: 1,
    text: "Home",
    url: "",
  },
  {
    id: 2,
    text: "About Us",
    url: "/about",
  },
  {
    id: 3,
    text: "Contact Us",
    url: "/contact",
  },
];

export const navRight = [
  {
    id: 10,
    text: "Salawats",
    url: "/salawats",
  },
  {
    id: 11,
    text: "Gosha E Durood",
    url: "/gosha-e-durood",
  },
  {
    id: 12,
    text: "Tasbeeh",
    url: "/tasbeeh",
  },
];

