import React, { useState, useEffect } from 'react';
import "./salawatslist.css";
import { Link } from 'react-router-dom';
import api from '../../../services/axiosConfig';

const SalawatsList = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        // Replace with your actual API URL
        const fetchPosts = async () => {
            try {
                const response = await api.get('/public/posts-random');
                setPosts(response.data);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchPosts();
    }, []);

    return (
        <section>
            <div className="salawat-list-liner">
                {posts.map((post) => (
                    <div key={post.id} className='salawat-card-liner'>
                        <Link to={`/salawat/${post.slug}`}>
                            <img src={post.image_url} alt={post.title} className='salawat-image-liner' />
                        </Link>
                        <div className='salawat-info-liner'>
                            <Link className="salawat-title-liner" to={`/salawat/${post.slug}`}>
                                {post.title}
                            </Link>
                        </div>
                    </div>

                ))}
            </div>
        </section>
    );
};

export default SalawatsList;
