import React, { useState } from "react";
import { Link } from "react-router-dom";
import { nav } from "../../../assets/data/data";
import { navRight } from "../../../assets/data/data";
export const Mmenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="rspn-hdr">
                <div className="lg-mn">
                    <span className="rspn-mnu-btn" onClick={toggleNavbar}>
                        <i className="fa fa-list-ul" />
                    </span>
                    <div className="logo">
                        <Link to={'/'}>
                            <img
                                src="/assets/images/logo/logo.png"
                                alt="logo.png"
                                style={{ width: 100 }}
                            />
                        </Link>
                    </div>
                    <div className="rspn-cnt"></div>
                </div>
                <div className={"rsnp-mnu ps " + (isOpen ? 'slidein' : '')}>
                    <span className="rspn-mnu-cls" onClick={toggleNavbar}>
                        <i className="fa fa-times" />
                    </span>
                    <ul>
                        {nav.map((link) => (
                            <li key={link.id}>
                                <Link to={link.url}>{link.text}</Link>
                            </li>
                        ))}
                        {navRight.map((link) => (
                            <li key={link.id}>
                                <Link to={link.url}>{link.text}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                {/* Responsive Menu */}
            </div >
        </>
    );
};
