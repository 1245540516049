import React from "react";
import "./loader.css";

export const Loader = () => {
    return (
        <>
            <div className="pageloader-wrap">
                <div className="loader">
                    <img src="/assets/images/logo/logo-footer.png" alt="Footer Logo" />
                </div>
            </div>
        </>
    );
};
