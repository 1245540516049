import React from "react";
import { Helmet } from 'react-helmet';
import "./goshaedurood.css";
import { Breadcrumb } from "../../components/breadcrumb/Breadcrumb";

export const GoshaEDurood = () => {
    return (
        <>
            <Helmet>
                <title>Gosha E Durood | Beloved Muhammad</title>
                <meta name="description" content="Hello" />
                <meta name="keywords" content="Hello" />
            </Helmet>
            <Breadcrumb name="Gosha E Durood" />
            <section>
                <div className="bgimg">
                    <div className="middle">
                        <h1>COMING SOON</h1>
                        <hr />
                    </div>
                </div>
            </section>
        </>

    );
};
