import React, { useState, useEffect } from 'react'
import "./header.css";

import { LeftMenu } from "./menu/LeftMenu";
import { RightMenu } from "./menu/RightMenu";
import { Mmenu } from "./mobile/Mmunu";
import { Logo } from "./logo/Logo";

export const Header = () => {
  const [darkMode, setDarkMode] = useState(false);
  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode) {
      setDarkMode(JSON.parse(savedMode));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', darkMode);
    document.body.className = darkMode ? 'dark-mode' : '';
  }, [darkMode]);

  return (
    <>
      <header className={`style header-3d ${darkMode ? 'dark-mode' : ''}`}>
        <div className="container">
          <LeftMenu />
          <Logo />
          <RightMenu />
        </div>
      </header >
      <Mmenu />
    </>
  );
};
