import React from "react";

export const Welcome = () => {
    return (
        <section>
            <div className="gap top-spac70 bottom-spac60">
                <div className="container">
                    <div className="hstry-wrap">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-lg-3">
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-6">
                                <div className="hstry-desc text-center">
                                    <span>Welcome To </span>
                                    <h2>Beloved <b>Muhammad</b></h2>
                                    <p>
                                        We unite Muslims of all backgrounds and welcome those interested in Islam by curating a comprehensive library of Darood Sharif (salutations upon Prophet <b>Muhammad</b> (ﷺ)) from around the world. We offer free, beautiful recitations with translations and audio playback, making it easier for everyone to learn, listen, and share these sacred verses.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-3">
                            </div>
                        </div>
                    </div>
                    {/* History Wrap */}
                </div>
            </div>
        </section>

    );
};
