import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CgPlayButtonO, CgPlayPauseO } from "react-icons/cg";
import './slider.css';
import api from '../../../services/axiosConfig';

import { Loader } from '../../../components/loader/loader';
import { CustomDropdown } from '../../../components/customDropdown/CustomDropdown';

import { SalawatShare } from '../SalawatShare';

export const Slider = () => {

    const navigate = useNavigate();

    const audioRef = useRef(null);
    const [currentAudio, setCurrentAudio] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const [images, setImages] = useState([]);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const [post, setPost] = useState(null);

    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const [navigationIds, setNavigationIds] = useState({
        previousPostId: null,
        nextPostId: null,
    });

    const fetchPostWithNavigation = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/public/post-random`);
            setImages(response.data.backgroundImages); // Extract images from the response
            setPost(response.data.currentSalawat);     // Extract the single post from the response
            setImages(response.data.backgroundImages);
            if (response.data.currentSalawat.active_translations.length > 0) {
                setSelectedLanguage(response.data.currentSalawat.active_translations[0].language.id);
            }
            setNavigationIds({
                previousPostId: response.data.previousSalawatId,
                nextPostId: response.data.nextSalawatId,
            });

        } catch (error) {
            setError('Failed to load the post. Please try again later.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPostWithNavigation();
    }, []);

    useEffect(() => {
        // Change the background image every 5 seconds
        const changeImage = () => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        };
        // // Set an interval to change the background every 5 seconds
        const intervalId = setInterval(changeImage, 15000);
        // // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [images]);

    const handleNavigation = (id) => {
        navigate(`/salawat/${id}`);
    };

    if (!post) {
        return <p>Salawat not found.</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }
    const translation = post.active_translations.find(t => t.language.id === selectedLanguage);

    const options = post.active_translations.map(translation => ({
        id: translation.language.id,
        value: translation.language.code,
        label: translation.language.name,
        flag: translation.language.image_url,
        locale: translation.language.locale
    }));

    const playAudio = (audioFile) => {
        const newAudio = audioFile;
        if (audioRef.current) {
            if (isPlaying && newAudio === currentAudio) {
                audioRef.current.pause();
                setIsPlaying(false);
                return;
            }
        }
        if (audioRef.current) {
            if (newAudio !== currentAudio) {
                setCurrentAudio(newAudio);
                audioRef.current.src = newAudio;
            }
            audioRef.current.play();
            setIsPlaying(true);
        }
    };

    return (
        <>
            <section>
                {loading ? (
                    <Loader />
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <>
                        {post && (
                            <>
                                <div className="slider-container" key={post.id} style={{
                                    backgroundImage: `url(${images[currentImageIndex].image_url})`, // Access the URL from the image object
                                }}>
                                    <div className="inner-container">
                                        <div className='slider-left'>
                                        </div>
                                        <div className="slider">
                                            <div className='container salawat-content'>
                                                <div className='arabic'>
                                                    <article className="post-arabic thm-play-btn">
                                                        بِسْمِ اللهِ الرَّحْمٰنِ الرَّحِيْمِ
                                                    </article>
                                                    <br />
                                                    <article className="post-arabic thm-play-btn">
                                                        {post.content}
                                                    </article>
                                                    <button className='thm-play-btn' onClick={() => playAudio(post.audio_url)}>
                                                        {currentAudio === post.audio_url && isPlaying ? <CgPlayPauseO /> : <CgPlayButtonO />}
                                                    </button>

                                                </div>
                                                <hr className='post-divider' />
                                                <button className="prev" disabled={!navigationIds.previousPostId} onClick={() => handleNavigation(navigationIds.previousPostId)}>❮</button>
                                                <button className="next" disabled={!navigationIds.nextPostId} onClick={() => handleNavigation(navigationIds.nextPostId)}>❯</button>
                                                <div className="translation container">
                                                    {selectedLanguage && (<CustomDropdown
                                                        options={options}
                                                        selectedValue={selectedLanguage}
                                                        onChange={setSelectedLanguage}
                                                    />)}
                                                    <br />
                                                    {translation && (
                                                        <>
                                                            <article className="translation-content thm-play-btn">
                                                                {translation.content}
                                                            </article>
                                                            <button className='thm-play-btn' onClick={() => playAudio(translation.audio_url)} >
                                                                {currentAudio === translation.audio_url && isPlaying ? <CgPlayPauseO /> : <CgPlayButtonO />}
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                                <div style={{ display: 'none' }}>
                                                    <audio ref={audioRef} controls />
                                                </div>
                                                <br />
                                                <SalawatShare post={post} />
                                            </div>
                                        </div>

                                        <div className='slider-left'>
                                        </div>
                                    </div>

                                </div>

                            </>
                        )}
                    </>
                )
                }
            </section>
        </>
    );
};

