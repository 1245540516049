import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import "./salawats.css";



import { Breadcrumb } from "../../components/breadcrumb/Breadcrumb";
import { Pagination } from './pagination/Pagination';
import { Loader } from '../../components/loader/loader';

import api from '../../services/axiosConfig';

export const Salawats = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(12);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchPosts = async (page, perPage) => {
      setLoading(true);
      try {
        const response = await api.get('/public/salawats', {
          params: { page, perPage },
        });
        setPosts(response.data.data);
        setTotalPages(response.data.last_page);
      } catch (error) {
        setError('Failed to load posts. Please try again later.');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchPosts(currentPage, perPage);
  }, [currentPage, perPage]);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  return (
    <>
      <Helmet>
        <title>Salawats | Beloved Muhammad</title>
        <meta name="description" content="Hello" />
        <meta name="keywords" content="Hello" />
      </Helmet>

      <Breadcrumb name="Salawats" />

      <section>
        <div className="gap">
          {loading ? (
            <Loader />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <div className="container " >

              <div className="row salawat-list">
                {posts.map((post) => (
                  <div className='col-md-3 col-sm-3 col-lg-3' key={post.id}>
                    <div className='salawat-card'>
                      <div className="blog-thmb">
                        <Link to={`/salawat/${post.slug}`}>
                          {post.image_url && (
                            <img
                              src={post.image_url}
                              alt={post.title}
                              className='salawat-image'
                            />
                          )}
                        </Link>
                      </div>
                      <div className='salawat-info'>
                        <Link className="salawat-title" to={`/salawat/${post.slug}`}>
                          {post.title}
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>


              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />


            </div>
          )}
        </div>
      </section>
    </>

  );
};


