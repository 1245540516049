import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/loader/loader'

import api from '../../services/axiosConfig';

export const Salawat = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchLatestPosts();
    }, []);

    const fetchLatestPosts = async () => {
        setLoading(true);
        try {
            const response = await api.get('/public/salawats-latest');
            setPosts(response.data);
        } catch (error) {
            setError('Failed to load latest posts. Please try again later.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    if (error) {
        return <p>{error}</p>;
    }
    return (
        <section>
            <div className="gap top-spac30">
                {loading ? (
                    <Loader />
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <div className="container" >
                        <div className="sec-title2 text-center">
                            <div className="sec-title-inner2">
                                <p >Serving Humanity</p>
                                <h3>Latest Salawats</h3>
                            </div>
                        </div>
                        <div className="blog-wrap remove-ext7">
                            <div className="row salawat-list">
                                {posts.map((post, index) => (
                                    <div className='col-md-3 col-sm-3 col-lg-3' key={post.id}>
                                        <div className='salawat-card' >
                                            <div className="blog-thmb">
                                                <Link to={`/salawat/${post.slug}`}>
                                                    {post.image_url && (
                                                        <img
                                                            src={post.image_url}
                                                            alt={post.title}
                                                            className='salawat-image'
                                                        />
                                                    )}
                                                </Link>
                                            </div>
                                            <div className='salawat-info'>
                                                <Link className="salawat-title" to={`/salawat/${post.slug}`}>
                                                    {post.title}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                ))}
                            </div>
                            <hr />
                            <br />
                        </div>

                        <div className="view-all text-center">
                            <Link to='/salawats' className="thm-btn brd-rd5" title="">
                                View More
                            </Link>

                        </div>

                    </div>
                )}
            </div>

        </section >
    );
};

