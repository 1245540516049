import React, { useState } from 'react';
import "./ContactForm.css";

import axios from '../../../services/axiosConfig'; // Ensure axiosConfig is set up with your API base URL

export const ContactForm = () => {

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        subject: '',
        message: '',
    });

    const [responseMessage, setResponseMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios
            .post('/contact', formData)
            .then((response) => {
                setResponseMessage(response.data.message);
                setFormData({ name: '', email: '', message: '' });
            })
            .catch((error) => {
                setResponseMessage('An error occurred. Please try again.');
                console.error(error);
            });
    };
    return (
        <>
            <section>
                <div className="gap">
                    <div className="container">
                        <div className="sec-title text-center">
                            <div className="sec-title-inner">
                                <span>Have Question</span>
                                <h3>Send Message</h3>
                            </div>
                        </div>
                        <div className="contact-form text-center">
                            <form onSubmit={handleSubmit}>
                                <div className="row mrg20">
                                    <div className="col-md-6 col-sm-6 col-lg-6">
                                        <input type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                            placeholder="Name" />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-lg-6">
                                        <input type="text"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}

                                            placeholder="Phone" />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-lg-6">
                                        <input type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                            placeholder="Email" />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-lg-6">
                                        <div className="form-group">
                                            <select className="form-select" onChange={handleChange} id="subject" name="subject" required placeholder="Subject">

                                                <option value="General Inquiry">
                                                    General Inquiry
                                                </option>
                                                <option value="Bug Fix">
                                                    Bug Fix
                                                </option>
                                                <option value="Suggestions">
                                                    Suggestions
                                                </option>
                                                <option value="Feedback">
                                                    Feedback
                                                </option>
                                                <option value="Report Content">
                                                    Report Content
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                        <textarea name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            required placeholder="Message"
                                            defaultValue={""} />
                                        {responseMessage && <p>{responseMessage}</p>}
                                        <button className="thm-btn brd-rd5" type="submit">
                                            Contact Us
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

