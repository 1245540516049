import React from "react";
import { Link } from "react-router-dom";

export const Breadcrumb = (props) => {

    return (
        <>
            <section>
                <div className="gap remove-bottom black-layer2 opc85">
                    <div className="fixed-bg" />
                    <div className="container">
                        <div className="page-title-wrap">
                            <h2>{props.name}</h2>
                            <ul className="breadcrumbs">
                                <li>
                                    <Link to={'/'}>
                                        Home
                                    </Link>
                                </li>
                                <li>{props.name}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}