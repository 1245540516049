import React from "react";
import { Helmet } from 'react-helmet';
import "./style.css";

import { Breadcrumb } from "../../components/breadcrumb/Breadcrumb";
import { ContactForm } from "./ContactForm/ContactForm";

export const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us | Beloved Muhammad</title>
        <meta name="description" content="Hello" />
        <meta name="keywords" content="Hello" />
      </Helmet>
      <Breadcrumb name="Contact Us" />
      <ContactForm />
    </>

  );
};
