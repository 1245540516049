import React from "react";
import { Link } from "react-router-dom";
import { navRight } from "../../../assets/data/data";

export const RightMenu = () => {
    return (
        <>
            <nav className="menu-right">
                <div>
                    <ul>
                        {navRight.map((link) => (
                            <li key={link.id}>
                                <Link to={link.url}>{link.text}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>
        </>
    );
};
