import React from "react";
import "./footer.css";

import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <>
      <footer>
        <div className="gap top-spac30 drk-bg2 remove-bottom">
          <div className="container">
            <div className="footer-data">
              <div className="row">
                <div className="col-md-4 col-sm-12 col-lg-4">
                  <div className="wdgt-box">
                    <div className="logo">
                      <Link to={'/'}>
                        <img src="/assets/images/logo/logo-footer.png" alt="Footer Logo" width="150px" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-12 col-lg-8">
                  <div className="row">
                    <div className="col-md-4 col-sm-4 col-lg-4">
                      <div className="wdgt-box style2">
                        <h4>Quick Links</h4>
                        <ul>
                          <li>
                            <Link to={'/about'}>
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link to={'/contact'}>
                              Contact Us
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-lg-4">
                      <div className="wdgt-box style2">
                        <h4>Information</h4>
                        <ul>
                          <li>
                            <Link to={'/terms-conditions'}>
                              Terms &amp; Conditions
                            </Link>
                          </li>
                          <li>
                            <Link to={'/privacy-policy'}>
                              Privacy Policy
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="bottom-bar">
              <p className="thm-clr">© Copyright 2024. All Rights Reserved. Website owned by : Advocate Muhammad Tahir Khan. Devleoped by <a href="https://inovatecsolutions.com/" target="_blank" rel="noopener noreferrer">
                INOVATEC Solutions FZE
              </a>
              </p>
              <div className="scl">
                <a href="/#" title="Twitter" target="_blank">
                  <i className="fa fa-twitter" />
                </a>
                <a href="/#" title="Pinterest" target="_blank">
                  <i className="fa fa-pinterest-p" />
                </a>
                <a href="/#" title="Google Plus" target="_blank">
                  <i className="fa fa-google-plus" />
                </a>
                <a href="/#" title="Facebook" target="_blank">
                  <i className="fa fa-facebook" />
                </a>
              </div>
            </div>

          </div>
        </div >
      </footer >

    </>
  );
};
