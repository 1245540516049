import React from "react";
import "./menu.css";
import { Link } from "react-router-dom";
import { nav } from "../../../assets/data/data";

export const LeftMenu = () => {
    return (
        <>
            <nav className="menu-left ">
                <div>
                    <ul>
                        {nav.map((link) => (
                            <li key={link.id}>
                                <Link to={link.url}>{link.text}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>
        </>
    );
};
